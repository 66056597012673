// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-event-js": () => import("./../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-articles-index-js": () => import("./../src/templates/articles-index.js" /* webpackChunkName: "component---src-templates-articles-index-js" */),
  "component---src-templates-portfolio-index-js": () => import("./../src/templates/portfolio-index.js" /* webpackChunkName: "component---src-templates-portfolio-index-js" */),
  "component---src-templates-subject-index-js": () => import("./../src/templates/subject-index.js" /* webpackChunkName: "component---src-templates-subject-index-js" */),
  "component---src-templates-articles-single-js": () => import("./../src/templates/articles-single.js" /* webpackChunkName: "component---src-templates-articles-single-js" */),
  "component---src-templates-portfolio-single-js": () => import("./../src/templates/portfolio-single.js" /* webpackChunkName: "component---src-templates-portfolio-single-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-speaking-js": () => import("./../src/pages/speaking.js" /* webpackChunkName: "component---src-pages-speaking-js" */),
  "component---src-pages-subjects-js": () => import("./../src/pages/subjects.js" /* webpackChunkName: "component---src-pages-subjects-js" */)
}

